<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row>
          <v-col cols="12" md="10">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end" v-if="canConfig" class="mt-3">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigDDJJAportes()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a la configuración de DDJJ y aportes</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                col="9"
                class="pt-0 pb-0"
                align="start"
                align-self="center"
              >
                <v-btn-toggle dense v-model="text" color="primary" group>
                  <v-btn
                    value="DDJJ"
                    @click="changeTab(1)"
                    :disabled="banDDJJ && !banAportes"
                  >
                    DDJJ
                  </v-btn>
                  <v-btn
                    value="APORTES"
                    @click="changeTab(2)"
                    :disabled="!banDDJJ && banAportes"
                  >
                    Aportes recibidos
                  </v-btn>
                  <v-btn
                    value="DDJJVSAPORTES"
                    @click="changeTab(3)"
                    :disabled="banDDJJ && banAportes"
                  >
                    DDJJ vs Aportes recibidos
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="pt-0 pb-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>

            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Filtros comunes -->
                  <!-- Empresa -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      cache-items
                      v-model="empresaSelected"
                      :items="empresas"
                      :search-input.sync="empresaAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      outlined
                      return-object
                      dense
                      clearable
                      autocomplete="not"
                      label="Empresa"
                      hint="Nombre o CUIT"
                      persistent-hint
                      append-icon
                    >
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="empresasLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col v-if="banDDJJ" cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha proceso desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="[
                            !(fechaDesdeSelected && fechaHastaSelected) ||
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto',
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col v-if="banDDJJ" cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha proceso hasta"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            !(fechaDesdeSelected && fechaHastaSelected) ||
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto',
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- afiliado -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      ref="afiliado-autocomplete"
                      cache-items
                      v-model="afiliadoSelected"
                      :items="afiliados"
                      :search-input.sync="afiliadosAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      :filter="customFilter"
                      outlined
                      autocomplete="not"
                      dense
                      return-object
                      clearable
                      label="Afiliado"
                      hint="Apellido, DNI o N° afiliado"
                      persistent-hint
                      append-icon
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Doc: {{ item.doc }} - Nro afiliado:
                            {{ item.numero }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="afiliadosLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Periodo Desde -->
                  <v-col cols="12" md="4" sm="6" class="py-0">
                    <v-text-field
                      dense
                      ref="periodo-desde"
                      outlined
                      v-model="periodoDesde"
                      label="Período desde"
                      hint="Formato AAAAMM"
                      persistent-hint
                      type="text"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      v-mask="'######'"
                      :rules="
                        rules.periodoYyyyMm.concat([
                          rules.validDateRange(periodoDesde, periodoHasta),
                        ])
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Periodo Hasta -->
                  <v-col cols="12" md="4" sm="6" class="py-0">
                    <v-text-field
                      dense
                      ref="periodo-hasta"
                      outlined
                      v-model="periodoHasta"
                      label="Período hasta"
                      type="text"
                      hint="Formato AAAAMM"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      persistent-hint
                      v-mask="'######'"
                      :rules="
                        rules.periodoYyyyMm.concat([
                          rules.validDateRange(periodoDesde, periodoHasta),
                        ])
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Convenio -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      ref="convenio"
                      v-model="convenioSelected"
                      :items="convenios"
                      item-text="value"
                      item-value="id"
                      label="Convenio"
                      outlined
                      return-object
                      dense
                      clearable
                      autocomplete="off"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Tipo afiliado -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      ref="tipo-afiliado"
                      v-model="tipoAfiSelected"
                      :items="tipoAfiCombo"
                      item-text="value"
                      item-value="id"
                      label="Tipo afiliado"
                      outlined
                      multiple
                      dense
                      clearable
                      autocomplete="off"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Situación de revista -->
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="py-0"
                    v-if="banDDJJ && !banAportes"
                  >
                    <v-autocomplete
                      ref="sit-revista"
                      v-model="sitRevistaSelected"
                      :items="situacionesRevista"
                      item-text="description"
                      item-value="value"
                      label="Situación de revista"
                      outlined
                      return-object
                      dense
                      clearable
                      autocomplete="off"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Afiliados Inexistentes -->
                  <v-col cols="4" class="py-0">
                    <v-switch
                      v-model="afiInex"
                      label="Unicamente afiliados inexistentes"
                    ></v-switch>
                  </v-col>
                  <!-- Fecha de pago desde -->

                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      v-model="menu3"
                      v-if="banAportes"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaPagoDesdeSelected"
                          label="Fecha pago desde (solo para aportes recibidos)"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaPagoDesde = parseDateToIso(
                              fechaPagoDesdeSelected
                            )
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="[]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaPagoDesde"
                        no-title
                        @change="
                          fechaPagoDesdeSelected = formatDate(fechaPagoDesde)
                        "
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha de pago hasta -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      v-if="banAportes"
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaPagoHastaSelected"
                          label="Fecha pago hasta (solo para aportes recibidos)"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaPagoHasta = parseDateToIso(
                              fechaPagoHastaSelected
                            )
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="[]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaPagoHasta"
                        no-title
                        @change="
                          fechaPagoHastaSelected = formatDate(fechaPagoHasta)
                        "
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Convenio del aporte distinto al del afiliado -->
                  <v-col cols="6" class="py-0">
                    <v-switch
                      v-model="convenioAporteDistinto"
                      v-if="banAportes && !banDDJJ"
                      label="Convenio del aporte distinto al del afiliado"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            v-model="ddjjAportesSelected"
            :headers="switchHeaders()"
            item-key="cuit"
            :items="resultsDdjjAportes"
            :loading="loading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col :md="banAportes && !banDDJJ && convenioAporteDistinto && showHelp ? 5 : 7" sm="7" xs="7">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col 
                    :md="banAportes && !banDDJJ && convenioAporteDistinto && showHelp ? 4 : 3"
                    sm="5"
                    xs="5"
                    align="right"
                    v-if="canCambiarConvenioAportes"
                  >
                    <v-btn 
                      v-if="banAportes && !banDDJJ && canModificarConvenioAportes"
                      small
                      color="primary"
                      elevation="2"
                      @click="toggleModalModificarAportes()"
                      class="no-upper-case"
                    >
                      Modificar convenio en aportes
                    </v-btn>
                  </v-col>
                  <v-col
                    :md="banAportes && !banDDJJ && convenioAporteDistinto && showHelp ? 2 : 1"
                    sm="5"
                    xs="5"
                    align="right"
                    v-if="canGenerarAportes"
                  >
                    <v-btn
                      v-if="banAportes && !banDDJJ"
                      small
                      color="primary"
                      elevation="2"
                      @click="goToGenerarAportes()"
                      class="no-upper-case"
                    >
                      Generar aportes
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.totalEsperado`]="{ item }">
              <span>{{ addDecimals(item.totalEsperado) }}</span>
            </template>
            <template v-slot:[`item.contribucionEsperada`]="{ item }">
              <span>{{ addDecimals(item.contribucionEsperada) }}</span>
            </template>
            <template v-slot:[`item.aporteEsperado`]="{ item }">
              <span>{{ addDecimals(item.aporteEsperado) }}</span>
            </template>
            <template v-slot:[`item.diferencia`]="{ item }">
              <span>{{ addDecimals(item.diferencia) }}</span>
            </template>
            <template v-slot:[`item.contribucionRecibida`]="{ item }">
              <span>{{ addDecimals(item.contribucionRecibida) }}</span>
            </template>
            <template v-slot:[`item.totalRecibido`]="{ item }">
              <span>{{ addDecimals(item.totalRecibido) }}</span>
            </template>
            <template v-slot:[`item.aporteRecibido`]="{ item }">
              <span>{{ addDecimals(item.aporteRecibido) }}</span>
            </template>
            <template v-slot:[`item.otrosConceptos`]="{ item }">
              <span>{{ addDecimals(item.otrosConceptos) }}</span>
            </template>
            <template
              v-slot:[`body.append`]="{ headers }"
              v-if="resultsDdjjAportes.length > 0"
            >
              <tr class="totales text-end" v-if="banDDJJ && banAportes">
                <td v-for="(header, i) in headers" :key="i">
                  <div v-if="header.value == 'contribucionRecibida'">
                    Totales
                  </div>
                  <div class="text--end" v-if="header.value == 'totalEsperado'">
                    <strong> {{ addDecimals(totalEsperado) }}</strong>
                  </div>
                  <div v-if="header.value == 'totalRecibido'">
                    <strong> {{ addDecimals(totalRecibido) }}</strong>
                  </div>
                  <div v-if="header.value == 'diferencia'">
                    <strong> {{ addDecimals(diferencia) }}</strong>
                  </div>
                  <div v-else></div>
                </td>
              </tr>
              <tr class="totales text-end" v-if="!banDDJJ && banAportes">
                <td v-for="(header, i) in headers" :key="i">
                  <div v-if="header.value == 'empresaNombre'">Totales</div>
                  <div v-if="header.value == 'aporteRecibido'">
                    <strong> {{ addDecimals(aporteRecibido) }}</strong>
                  </div>
                  <div v-if="header.value == 'contribucionRecibida'">
                    <strong> {{ addDecimals(contribucionRecibida) }}</strong>
                  </div>
                  <div v-if="header.value == 'otrosConceptos'">
                    <strong> {{ addDecimals(otrosConceptos) }}</strong>
                  </div>
                  <div class="text--end" v-if="header.value == 'totalRecibido'">
                    <strong> {{ addDecimals(totalRecibido) }}</strong>
                  </div>
                  <div v-else></div>
                </td>
              </tr>
              <tr class="totales text-end" v-if="banDDJJ && !banAportes">
                <td v-for="(header, i) in headers" :key="i">
                  <div v-if="header.value == 'empresaNombre'">Totales</div>
                  <div v-if="header.value == 'aporteEsperado'">
                    <strong> {{ addDecimals(aporteEsperado) }}</strong>
                  </div>
                  <div v-if="header.value == 'contribucionEsperada'">
                    <strong> {{ addDecimals(contribucionEsperada) }}</strong>
                  </div>
                  <div v-if="header.value == 'totalEsperado'">
                    <strong> {{ addDecimals(totalEsperado) }}</strong>
                  </div>
                  <div v-else></div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-col cols="12" class="to-right">
            <vue-excel-xlsx
              :data="resultsExportExcel"
              :columns="columnsExcel()"
              :filename="fileName()"
              :sheetname="sheetName()"
              class="to-right"
            >
              <v-tooltip left max-width="40%">
                <template v-slot:activator="{ attrs }">
                  <v-btn
                    v-bind="attrs"
                    class="pr-4 fontsize"
                    color="primary"
                    outlined
                    >Exportar detalle</v-btn
                  >
                </template>
              </v-tooltip>
            </vue-excel-xlsx>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalModificarAportes"
      v-model="modalModificarAportes"
      max-width="85%"
      @keydown.esc="toggleModalModificarAportes"
      persistent
    >
      <ModificarConvenioAportes
        :ItemsAportes="ItemsAportes"
        @toggleModalModificarAportes="toggleModalModificarAportes"
      ></ModificarConvenioAportes
    ></v-dialog>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import HistoricEstadoActa from "@/components/modules/cuotas/aportes/HistoricEstadoActa";
import ModificarConvenioAportes from "@/components/modules/cuotas/aportes/ModificarConvenioAportes.vue";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "DDJJyAportes",
  components: {
    PageHeader,
    FiltersSelected,
    HistoricEstadoActa,
    Ayuda,
    ModificarConvenioAportes
  },
  directives: { mask },
  data: (vm) => ({
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
        .toISOString()
        .substr(0, 10)
    ),
    fechaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaPagoDesdeSelected: null,
    fechaPagoHastaSelected: null,
    vencimientoHastaSelected: vm.formatDate(
      new Date(new Date().valueOf() + 1000 * 60 * 60 * 24 * 60)
        .toISOString()
        .substring(0, 10)
    ),
    openSentEmailsResult: false,
    title: enums.titles.CONSULTA_DDJJ_APORTES,
    titleExportPdf: enums.titles.EXPORTAR_PDF,
    titleModifAportes: "Modificar aportes?",
    searchIcon: enums.icons.SEARCH,
    warningIcon: enums.icons.SNB_WARNING,
    periodoDesde: null,
    periodoHasta: null,
    showExpand: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.CONSULTA_DDJJ_APORTES,
    showIcon: true,
    rules: rules,
    settingsIcon: enums.icons.SETTINGS,
    canConfig: false,
    canCambiarConvenioAportes: false,
    isLoading: false,
    expanded: [],
    search: "",
    loading: false,
    singleExpand: true,
    headers: [],
    showSearchFilter: false,
    afiInex: false,
    isProcess: false,
    canModificarConvenioAportes: false,
    modalModificarAportes: false,
    resultsDdjjAportes: [],
    previousValue: null,
    showFilters: true,
    filtersApplied: [],
    isFormValid: true,
    empresaSelected: null,
    empresaAutocompleteInput: null,
    ItemsAportes: null,
    empresasLoading: false,
    afiliadosAutocompleteInput: null,
    afiliadosLoading: false,
    ddjjAportesSelected: [],
    empresas: [],
    afiliados: [],
    convenios: [],
    convenioSelected: null,
    convenioAporteDistinto: false,
    periodoDdjj: null,
    singleSelect: false,
    afiliadoSelected: null,
    pageCount: 0,
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .substr(0, 10),
    fechaPagoDesde: null,
    fechaPagoHasta: null,
    fechaHasta: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    defaultErrorMsg: enums.messages.SYSTEM_ERROR,
    infoIcon: enums.icons.SNB_INFO,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
    updateStateIcon: enums.icons.UPDATE,
    historicStatesIcon: enums.icons.HISTORIC,
    tipoAfiCombo: [],
    tipoAfiSelected: [],
    optionCode: enums.webSiteOptions.CONSULTA_DDJJ_APORTES,
    banDDJJ: true,
    banAportes: true,
    resultsExportExcel: [],
    excelIcon: enums.icons.EXPORT_EXCEL,
    text: "DDJJVSAPORTES",
    allowedActions: null,
    //totales ddjj vs aportes
    diferencia: 0,
    //totales aportes
    aporteRecibido: 0,
    //comparten
    totalRecibido: 0,
    contribucionRecibida: 0,
    otrosConceptos: 0,
    //ddjj
    aporteEsperado: 0,
    contribucionEsperada: 0,
    totalEsperado: 0,
    sitRevistaSelected: null,
    situacionesRevista: [],
    canGenerarAportes: false,
  }),
  computed: {
    showConfirmItemsSelected() {
      if (this.actasSelected.length < this.actasFiscalizacion.length) {
        return this.pageCount > 1;
      }
      return false;
    },
    multiselectIcon() {
      if (this.estadosActaSeleccionados) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    sortBy: {
      get() {
        return this.isProcess ? this.sortByProceso : this.sortByActa;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
  watch: {
    isProcess() {
      this.cleanFiltersSelected();
    },
    empresaAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getEmpresasFilter();
      }
    },
    afiliadosAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getAfiliadosFilter();
      }
    },
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.getConvenios();
    this.getEstadosActa();
    this.getTiposAfi();
    this.getSituacionesRevista();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      fetchAfiliadosByQuery: "aportes/fetchAfiliadosByQuery",
      getTiposBenef: "afiliaciones/getTiposBenef",
      fetchConvenios: "aportes/fetchConvenios",
      fetchEstadosActa: "configuracion/fetchEstadosActa",
      getDDJJVsAportesByParams: "aportes/getDDJJVsAportesByParams",
      getDDJJByParams: "aportes/getDDJJByParams",
      fetchSituacionesRevistas: "aportes/fetchSituacionesRevistas",
      getAportesByParams: "aportes/getAportesByParams",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .CONFIGURACION_DDJJ_APORTES:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions.CARGA_APORTES:
            this.canGenerarAportes = true;
            break;
          case enums.modules.adminSistema.permissions.CAMBIO_CONVENIO_APORTES:
            this.canCambiarConvenioAportes = true;
            break;
          default:
            break;
        }
      });
    },
    async getSituacionesRevista() {
      const sitRevistas = await this.fetchSituacionesRevistas();
      this.situacionesRevista = sitRevistas;
    },
    async applyFilters() {
      this.loading = true;
      this.customizeFiltersApplied();
      const params = {
        empresaId: this.empresaSelected ? this.empresaSelected.id : -1,
        fechaProcesoDesde: this.fechaDesdeSelected
          ? `${this.parseDate(this.fechaDesdeSelected)} 00:00:00`
          : null,
        fechaProcesoHasta: this.fechaHastaSelected
          ? `${this.parseDate(this.fechaHastaSelected)} 23:59:00`
          : null,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
        benId: this.afiliadoSelected ? this.afiliadoSelected.id : -1,
        convenioId: this.convenioSelected ? this.convenioSelected.id : -1,
        tiposDeAfiliado:
          this.tipoAfiSelected.length > 0 ? this.tipoAfiSelected : [],
        fechaPagoDesde:
          this.fechaPagoDesdeSelected && this.banAportes == true
            ? `${this.parseDate(this.fechaPagoDesdeSelected)} 00:00:00`
            : null,
        fechaPagoHasta:
          this.fechaPagoHastaSelected && this.banAportes == true
            ? `${this.parseDate(this.fechaPagoHastaSelected)} 23:59:00`
            : null,
        soloAfiliadosInexistentes: this.afiInex,
        banDDJJ: this.banDDJJ,
        banAportes: this.banAportes,
        sitCuil: this.sitRevistaSelected ? this.sitRevistaSelected.value : null,
        convenioAporteDistintoAfiliado: this.convenioAporteDistinto,
      };
      if (this.banDDJJ && this.banAportes) {
        try {
          const response = await this.getDDJJVsAportesByParams(params);
          this.resultsDdjjAportes = response.empresas;
          this.totalEsperado = response.totalEsperado;
          this.resultsExportExcel = response.afiliadosExcel;
          this.totalRecibido = response.totalRecibido;
          this.diferencia = response.totalSaldo;
          if (this.resultsDdjjAportes.length > 0) {
            this.showFilters = false;
          }
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else if (!this.banDDJJ && this.banAportes) {
        try {
          const response = await this.getAportesByParams(params);
          this.resultsDdjjAportes = response.empresas;
          this.resultsExportExcel = response.afiliadosExcel;
          this.aporteRecibido = response.totalAporte;
          this.contribucionRecibida = response.totalContribucion;
          this.otrosConceptos = response.totalOtrosConceptos;
          this.totalRecibido = response.totalRecibido;
          if (this.resultsDdjjAportes.length > 0) {
            this.showFilters = false;
          }
          this.loading = false;
          if(this.convenioAporteDistinto && this.resultsExportExcel.length !== 0) this.canModificarConvenioAportes = true;
          else this.canModificarConvenioAportes = false;
        } catch {
          this.loading = false;
        }
      } else {
        try {
          const response = await this.getDDJJByParams(params);
          this.resultsDdjjAportes = response.empresas;
          this.aporteEsperado = response.totalAporteEsperado;
          this.resultsExportExcel = response.afiliadosExcel;
          this.contribucionEsperada = response.totalContribucionEsperada;
          this.totalEsperado = response.totalEsperado;
          if (this.resultsDdjjAportes.length > 0) {
            this.showFilters = false;
          }
          this.loading = false;
        } catch {
          this.loading = false;
        }
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.empresaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "empresaId",
          label: "Empresa ",
          model: this.empresaAutocompleteInput,
        });
      }
      if (this.afiliadoSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "benId",
          label: "Afiliado",
          model: this.afiliadosAutocompleteInput,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "fechaProcesoDesde",
          label: "Fecha proceso desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "fechaProcesoHasta",
          label: "Fecha proceso hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.periodoDesde) {
        this.filtersApplied.splice(4, 1, {
          key: "periodoDesde",
          label: "Período desde",
          model: this.periodoDesde,
        });
      }
      if (this.periodoHasta) {
        this.filtersApplied.splice(5, 1, {
          key: "periodoHasta",
          label: "Período hasta",
          model: this.periodoHasta,
        });
      }
      if (this.convenioSelected) {
        this.filtersApplied.splice(6, 1, {
          key: "convenioId",
          label: "Convenio",
          model: this.convenioSelected.value,
        });
      }
      if (this.tipoAfiSelected) {
        this.filtersApplied.splice(7, 1, {
          key: "value",
          label: "Tipo afiliado",
          model: this.tipoAfiSelected,
        });
      }
      if (this.fechaPagoDesdeSelected && this.banAportes) {
        this.filtersApplied.splice(8, 1, {
          key: "fechaPagoDesde",
          label: "Fecha pago desde",
          model: this.fechaPagoDesdeSelected,
        });
      }
      if (this.fechaPagoHastaSelected && this.banAportes) {
        this.filtersApplied.splice(9, 1, {
          key: "fechaPagoHasta",
          label: "Fecha pago hasta",
          model: this.fechaPagoHastaSelected,
        });
      }
      if (this.afiInex) {
        this.filtersApplied.splice(10, 1, {
          key: "soloAfiliadosInexistentes",
          label: "Únicamente afiliados inexistentes",
          model: "Si",
        });
      }
      if (this.sitRevistaSelected && this.banDDJJ) {
        this.filtersApplied.splice(11, 1, {
          key: "sitRevistaSelected.value",
          label: "Situación de revista",
          model: this.sitRevistaSelected.description,
        });
      }
      if (this.convenioAporteDistinto) {
        this.filtersApplied.splice(12, 1, {
          key: "convenioAporteDistinto",
          label: "Convenio del aporte distinto al del afiliado",
          model: "Si",
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    async getConvenios() {
      const convenios = await this.fetchConvenios();
      this.convenios = convenios;
    },
    changeTab(num) {
      this.resultsDdjjAportes = [];
      this.resetForm();
      this.filtersApplied = [];
      if (this.showFilters === false) this.showFilters = true;
      this.totalEsperado = 0;
      this.contribucionEsperada = 0;
      this.aporteEsperado = 0;
      this.diferencia = 0;
      this.otrosConceptos = 0;
      this.aporteRecibido = 0;
      this.totalRecibido = 0;
      this.contribucionRecibida = 0;
      switch (num) {
        case 1:
          this.banDDJJ = true;
          this.banAportes = false;
          break;
        case 2:
          this.banDDJJ = false;
          this.banAportes = true;
          break;
        case 3:
          this.banDDJJ = true;
          this.banAportes = true;
          break;
        default:
          break;
      }
    },
    async getTiposAfi() {
      const datosTiposBenef = await this.getTiposBenef();
      this.tipoAfiCombo = datosTiposBenef;
    },
    async getEstadosActa() {
      const estadosActa = await this.fetchEstadosActa();
      this.estadosActa = estadosActa;
    },
    async getEmpresasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        this.empresasLoading = true;
        const res = await this.fetchEmpresasByQuery({
          input: this.empresaAutocompleteInput,
        });
        this.empresas = res;
        this.empresasLoading = false;
      }, 1000);
    },
    getAfiliadosFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.afiliados?.some(
            (x) => x.value == this.afiliadosAutocompleteInput
          )
        ) {
          return;
        } else {
          this.afiliadosLoading = true;
          const afiliados = await this.fetchAfiliadosByQuery({
            input: this.afiliadosAutocompleteInput,
          });
          this.$refs["afiliado-autocomplete"].cachedItems = [];
          this.afiliados = afiliados;
          this.afiliadosLoading = false;
        }
      }, 2000);
    },
    customFilter(item, queryText, itemText) {
      return (
        item.value ||
        item.doc.indexOf(queryText) > -1 ||
        item.numero.indexOf(queryText) > -1
      );
    },
    resetForm() {
      this.$refs["empresa-autocomplete"].reset();
      this.$refs["afiliado-autocomplete"].reset();
      this.$refs["convenio"].reset();
      this.$refs["tipo-afiliado"].reset();
      this.periodoDesde = null;
      this.periodoHasta = null;
      this.fechaDesde = null;
      this.fechaHasta = null;
      this.fechaPagoHasta = null;
      this.fechaPagoDesde = null;
      this.fechaDesdeSelected = null;
      this.fechaHastaSelected = null;
      this.fechaPagoHastaSelected = null;
      this.fechaPagoDesdeSelected = null;
      this.afiInex = false;
      this.sitRevistaSelected = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    toggleModalModificarAportes(){
      this.ItemsAportes = this.resultsExportExcel;
      this.modalModificarAportes = !this.modalModificarAportes;
      this.applyFilters();
    },
    columnsExcel() {
      if (this.banDDJJ && this.banAportes) {
        return [
          {
            label: "Período",
            field: "periodo",
          },
          {
            label: "CUIT",
            field: "cuit",
          },
          {
            label: "Empresa",
            field: "empresaNombre",
          },
          {
            label: "CUIL",
            field: "cuil",
          },
          {
            label: "Afiliado",
            field: "afiliadoNombre",
          },
          {
            label: "Aporte esperado",
            field: "aporteEsperado",
          },
          {
            label: "Contribución esperada",
            field: "contribucionEsperada",
          },
          {
            label: "Total esperado",
            field: "totalEsperado",
          },
          {
            label: "Aporte recibido",
            field: "aporteRecibido",
          },
          {
            label: "Contribución recibida",
            field: "contribucionRecibida",
          },
          {
            label: "Total recibido",
            field: "totalRecibido",
          },
          {
            label: "Saldo",
            field: "saldoGeneral",
          },
          {
            label: "Convenio",
            field: "obraSocialNombre",
          },
          {
            label: "Plan",
            field: "planNombre",
          },
          {
            label: "Tipo beneficiario",
            field: "tipoBenId",
          },
          {
            label: "Estado beneficiario",
            field: "estadoBenefNombre",
          },
          {
            label: "Localidad",
            field: "localidad",
          },
          {
            label: "Agrupación geográfica",
            field: "agrupacionGeografica",
          },
          {
            label: "TabMinAporteEsp",
            field: "tabMinAporteEsp",
          },
          {
            label: "TabMinContribucionEsp",
            field: "tabMinContribucionEsp",
          },
          {
            label: "TabMinSueldo",
            field: "tabMinSueldo",
          },
          {
            label: "TabMinTotalEsp",
            field: "tabMinTotalEsp",
          },
          {
            label: "TabMinDifEspRec",
            field: "tabMinDifEspRec",
          },
          {
            label: "CategoriaSindical",
            field: "categoriaSindical",
          },
          {
            label: "CategSindical_ApEsp",
            field: "categSindicalApEsp",
          },
          {
            label: "CategSindical_ContEsp",
            field: "categSindicalContEsp",
          },
          {
            label: "CategSindical_Sueldo",
            field: "categSindicalSueldo",
          },
          {
            label: "CategSindical_TotalEsp",
            field: "categSindicalTotalEsp",
          },
        ];
      } else if (!this.banDDJJ && this.banAportes) {
        return [
          {
            label: "Período",
            field: "periodo",
          },
          {
            label: "CUIT",
            field: "cuit",
          },
          {
            label: "Empresa",
            field: "empresaNombre",
          },
          {
            label: "CUIL",
            field: "cuil",
          },
          {
            label: "Afiliado",
            field: "afiliadoNombre",
          },
          {
            label: "Aporte",
            field: "aporte",
          },
          {
            label: "Contribución",
            field: "contribucion",
          },
          {
            label: "Otros conceptos",
            field: "otrosConceptos",
          },
          {
            label: "Fecha",
            field: "fecha",
          },
          {
            label: "Convenio",
            field: "convenioNombre",
          },
          {
            label: "Plan",
            field: "planNombre",
          },
          {
            label: "Localidad",
            field: "localidad",
          },
          {
            label: "Agrupación geográfica",
            field: "agrupacionGeografica",
          },
          {
            label: "Es primer aporte",
            field: "esPrimerAporte",
          },
          {
            label: "Agente cuenta ID",
            field: "agectaId",
          },
          {
            label: "Agente cuenta nombre",
            field: "agectaNombre",
          },
        ];
      } else {
        return [
          {
            label: "Afiliado",
            field: "afiliadoNombre",
          },
          {
            label: "Período",
            field: "periodo",
          },
          {
            label: "CUIT",
            field: "cuit",
          },
          {
            label: "CUIL",
            field: "cuil",
          },
          {
            label: "RemosImp",
            field: "remosImp",
          },
          {
            label: "Imposad",
            field: "imposad",
          },
          {
            label: "Zona",
            field: "zona",
          },
          {
            label: "GrpFam",
            field: "grpFam",
          },
          {
            label: "Número grupo familiar",
            field: "nroGrpFam",
          },
          {
            label: "SecOblig",
            field: "secOblig",
          },
          {
            label: "CondCuil",
            field: "condCuil",
          },
          {
            label: "SitCuil",
            field: "sitCuil",
          },
          {
            label: "Activdes",
            field: "activdes",
          },
          {
            label: "Modalidad",
            field: "modalidad",
          },
          {
            label: "Codsini",
            field: "codsini",
          },
          {
            label: "Apadios",
            field: "apadios",
          },
          {
            label: "Version",
            field: "version",
          },
          {
            label: "Rem5",
            field: "rem5",
          },
          {
            label: "Esposa",
            field: "esposa",
          },
          {
            label: "Excosapo",
            field: "excosapo",
          },
          {
            label: "Indret",
            field: "indret",
          },
          {
            label: "Indexccon",
            field: "indexccon",
          },
          {
            label: "FecPresent",
            field: "fecPresent",
          },
          {
            label: "FecProc",
            field: "fecProc",
          },
          {
            label: "Origrect",
            field: "origrect",
          },
          {
            label: "Remcont",
            field: "remcont",
          },
          {
            label: "Aporte",
            field: "aporte",
          },
          {
            label: "Contribución",
            field: "contribucion",
          },
          {
            label: "FechaProceso",
            field: "fechaProceso",
          },
        ];
      }
    },
    switchHeaders() {
      if (this.banDDJJ && this.banAportes) {
        return (this.headers = [
          {
            text: "CUIT",
            align: "start",
            sortable: false,
            value: "cuit",
          },
          {
            text: "Empresa",
            sortable: false,
            value: "empresaNombre",
          },
          {
            text: "Aporte esperado",
            sortable: false,
            value: "aporteEsperado",
            align: "end",
          },
          {
            text: "Contrib. esperada",
            sortable: false,
            value: "contribucionEsperada",
            align: "end",
          },
          {
            text: "Aporte recibido",
            sortable: false,
            value: "aporteRecibido",
            align: "end",
          },
          {
            text: "Contrib. recibida",
            sortable: false,
            value: "contribucionRecibida",
            align: "end",
          },
          {
            text: "Total esperado",
            sortable: false,
            value: "totalEsperado",
            align: "end",
          },
          {
            text: "Total recibido",
            sortable: false,
            value: "totalRecibido",
            align: "end",
          },
          {
            text: "Diferencia",
            sortable: false,
            value: "diferencia",
            align: "end",
          },
        ]);
      } else if (!this.banDDJJ && this.banAportes) {
        return (this.headers = [
          {
            text: "CUIT",
            align: "start",
            sortable: false,
            value: "cuit",
          },
          {
            text: "Empresa",
            sortable: false,
            value: "empresaNombre",
          },
          {
            text: "Aporte recibido",
            sortable: false,
            value: "aporteRecibido",
            align: "end",
          },
          {
            text: "Contribución recibida",
            sortable: false,
            value: "contribucionRecibida",
            align: "end",
          },
          {
            text: "Otros conceptos",
            sortable: false,
            value: "otrosConceptos",
            align: "end",
          },
          {
            text: "Total recibido",
            sortable: false,
            value: "totalRecibido",
            align: "end",
          },
        ]);
      } else {
        return (this.headers = [
          {
            text: "CUIT",
            align: "start",
            sortable: false,
            value: "cuit",
          },
          {
            text: "Empresa",
            sortable: false,
            value: "empresaNombre",
          },
          {
            text: "Aporte esperado",
            sortable: false,
            value: "aporteEsperado",
            align: "end",
          },
          {
            text: "Contribución esperada",
            sortable: false,
            value: "contribucionEsperada",
            align: "end",
          },
          {
            text: "Total esperado",
            sortable: false,
            value: "totalEsperado",
            align: "end",
          },
        ]);
      }
    },
    fileName() {
      if (this.banDDJJ && this.banAportes) {
        return "DDJJ vs. Aportes";
      } else if (!this.banDDJJ && this.banAportes) {
        return "Aportes";
      } else {
        return "DDJJ";
      }
    },
    sheetName() {
      if (this.banDDJJ && this.banAportes) {
        return "DDJJ vs. Aportes";
      } else if (!this.banDDJJ && this.banAportes) {
        return "Aportes";
      } else {
        return "DDJJ";
      }
    },
    goToConfigDDJJAportes() {
      this.$router.push({
        name: "ConfiguracionDDJJAportes",
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    goToGenerarAportes() {
      this.$router.push({
        name: "CargaAportes",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-row {
  max-height: 80px;
}
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.icon-readonly {
  cursor: context-menu;
}
.my-tooltip {
  white-space: pre-line;
}
::v-deep label {
  margin-bottom: 0;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
</style>
